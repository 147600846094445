import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FaCog } from 'react-icons/fa';

const SettingsIcon = styled(FaCog)`
  position: fixed;
  top: 13px;
  left: 13px;
  font-size: 1.5rem;
  color: #fff;
  opacity: ${props => props.hovering? 1.0 : 0.3};
  transform: rotate(${props => props.rotate? '90deg' : '0'});
  cursor: pointer;
  transition: 250ms;
  z-index: 15;
`;

const SettingsToggle = ({ onClick, settingsOpen }) => {
  const [hovering, setHovering] = useState(false);

  return (
    <SettingsIcon
      hovering={Number(hovering || settingsOpen)}
      rotate={Number(settingsOpen)}
      onMouseEnter={() => setHovering(true)}
      onMouseLeave={() => setHovering(false)}
      onClick={onClick}
    />
  );
};

SettingsToggle.propTypes = {
  onClick: PropTypes.func.isRequired,
  settingsOpen: PropTypes.bool
};

SettingsToggle.defaultProps = {
  settingsOpen: false
};

export default SettingsToggle;