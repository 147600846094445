import React, { Component } from 'react';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false
    };
  }

  componentDidCatch(error, info) {
    this.setState({ hasError: true });
    console.error(error, info);
  }

  render() {
    if (this.state.hasError) {
      return (
        <h1 class="error">
          An error occurred while rendering the UI.
        </h1>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
