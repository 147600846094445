import React, { useContext } from 'react';
import { StreamsContext } from '../../contexts/StreamsContext';
import { Flex } from 'reflexbox';
import styled from 'styled-components';

const Link = styled.button`
  -webkit-appearance: none;
  background: transparent;
  border: none;
  font-family: inherit;
  font-weight: inherit;
  cursor: pointer;
  color: #fff;
  border-radius: 4px;
  font-size: 1rem;
  padding: 0;
  opacity: 0.5;
  transition: 150ms;

  &:hover {
    opacity: 1.0;
  }

  &:visited,
  &:active {
    color: inherit;
  }
`;

const ClearStreamsButton = () => {
  const { clearStreams } = useContext(StreamsContext);
  return (
    <Flex justifyContent="center" p={2}>
      <Link onClick={clearStreams}>Clear All</Link>
    </Flex>
  );
};

export default ClearStreamsButton;