import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Flex } from 'reflexbox';
import { FaTimes as CloseIcon } from 'react-icons/fa';

import { StreamsContext } from '../contexts/StreamsContext';
import streamTypes from './streamTypes';

const Channel = styled.div`
  float: left;
  width: ${props => props.width}px;
  height: ${props => props.height}px;
  position: relative;
`;
Channel.displayName = 'Channel';

const Controls = styled(Flex).attrs({
  justifyContent: 'center',
  alignItems: 'center',
  height: '34px',
  px: 1
})`
  position: absolute;
  right: 8px;
  top: 8px;
  font-size: 1.75rem;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.9);
  border-radius: 8px;
  text-align: center;
  opacity: ${props => props.show? 1.0 : 0.0};
  transition: 250ms linear;
  cursor: pointer;
  z-index: 1;
`;

const StreamBoard = ({width, height, type: streamType, stream}) => {
  const [showControls, setShowControls] = useState(false);
  const { removeStream } = useContext(StreamsContext)
  let contents;

  if (streamType === streamTypes.YOUTUBE.type) {
    contents =
      <iframe
        src={`https://www.youtube.com/embed/${stream.name}`}
        width={width}
        height={height}
        frameBorder="0"
        scrolling="no"
        allowFullScreen={true}
        title={stream.display_name}
      />;
  } else {
    const urlOptions = {
      channel: stream.name,
      muted: 'true',
      autoplay: 'true',
      parent: process.env.REACT_APP_HOSTNAME,
    };

    const urlQueryString = Object.keys(urlOptions)
      .map(key => `${key}=${urlOptions[key]}`)
      .join('&');
    
    contents =
      <iframe
        src={`https://player.twitch.tv/?${urlQueryString}`}
        width={width}
        height={height}
        frameBorder="0"
        scrolling="no"
        allowFullScreen={true}
        title={stream.display_name}
      />;
  }

  return (
    <Channel
      width={width}
      height={height}
      onMouseEnter={() => setShowControls(true)}
      onMouseLeave={() => setShowControls(false)}
    >
      <Controls show={showControls}>
        <CloseIcon onClick={() => removeStream(stream.name)} />
      </Controls>

      {contents}
    </Channel>
  );
};

StreamBoard.propTypes = {
  stream: PropTypes.object,
  type: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

StreamBoard.defaultProps = {
  stream: {},
  type: streamTypes.TWITCH.type,
  width: 0,
  height: 0,
};

export default StreamBoard;
