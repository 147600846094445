import React, { useState, useContext, Component } from 'react';
import styled from 'styled-components';
import { Box } from 'reflexbox';
import { StreamsContext } from '../../contexts/StreamsContext';

import SettingsToggle from './SettingsToggle';
import ToggleFullscreen from './ToggleFullscreen';
import Overlay from './Overlay';
import AddStreamForm from './AddStreamForm';
import StreamTable from '../StreamTable';
import ClearStreamsButton from './ClearStreamsButton';
import VersionBadge from './VersionBadge';

const Container = styled(Box).attrs({
  maxWidth: ['100%', '400px'],
  width: ['100%', '80%'],
  height: '100%',
  pt: '48px',
})`
  position: fixed;
  top: 0;
  left:0;
  transform: translateX(${props => props.open? '0' : '-100%'});
  transition: 150ms ease-in;
  font-size: 1rem;
  text-align: left;
  color: #fff;
  background: rgba(0, 0, 0, 0.9);
  border-right: 1px solid rgba(0, 0, 0, 0.5);
  z-index: 10;
  ${props => props.open &&
    'box-shadow: rgba(0, 0, 0, 0.3) 3px 0 7px;'
  }

  @media screen and (max-width: 640px) {
    transform: translateY(${props => props.open? '0' : '-100%'});
  }

  @supports (-webkit-backdrop-filter: blur(5px)) {
    background: rgba(0, 0, 0, 0.5);
    -webkit-backdrop-filter: blur(20px);
    border-right: none;
  }

  @supports (backdrop-filter: blur(5px)) {
    background: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(20px);
    border-right: none;
  }
`;
Container.displayName = 'NavMenuContainer';

const NavMenu = () => {
  const { streams } = useContext(StreamsContext);
  const [open, setOpen] = useState(!streams.length);

  const toggleOpen = () => setOpen(!streams.length? true : !open);

  return (
    <>
      <SettingsToggle onClick={toggleOpen} settingsOpen={open} />

      {open && <Overlay onClick={toggleOpen} />}

      <Container open={open}>
        <ToggleFullscreen show={open} />
        <AddStreamForm open={open} />
        <StreamTable streams={streams} />
        {!!streams.length && <ClearStreamsButton />}
        <VersionBadge version="1.4.1" />
      </Container>
    </>
  );
};

export default NavMenu;
