import React, { useState } from 'react';
import styled from 'styled-components';
import { Flex, Box } from 'reflexbox';

import ControlStrip from './ControlStrip';

const CellContainer = styled(Flex).attrs({
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  height: '45px',
})`
  position: relative;
  overflow: hidden;
`;

const ChannelImage = styled(Box).attrs({
  height: '45px',
  width: '45px',
  flexShrink: 0,
})`
  background-image: ${props => `url(${props.image || ''})`};
  background-size: cover;
  background-position: center center;
`;
ChannelImage.displayName = 'ChannelImage';

const ChannelName = styled(Box).attrs({
  flexGrow: 1,
  pl: 2,
})`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;
ChannelName.displayName = 'ChannelName';

const TableCell = props => {
  const [showControls, setShowControls] = useState(false);

  return (
    <CellContainer
      onMouseEnter={() => setShowControls(true)}
      onMouseLeave={() => setShowControls(false)}
    >
      <ChannelImage image={props.image} />
      <ChannelName>
        {props.text}
      </ChannelName>
      <ControlStrip show={showControls} stream={props.stream} />
    </CellContainer>
  )
};

export default TableCell;
