const streamTypes = {
  TWITCH: {
    type: 'twitch',
    short: 'tw'
  },
  YOUTUBE: {
    type: 'youtube',
    short: 'yt'
  }
};

export default streamTypes;