const weAreFullscreen = () => (
  document.fullscreenElement ||
  document.mozFullScreenElement ||
  document.webkitFullscreenElement ||
  document.msFullscreenElement
);

const toggleFullScreen = () => {
  const body = document.body;

  if (weAreFullscreen()) {
    if (document.exitFullscreen) document.exitFullscreen();
    if (document.webkitExitFullscreen) document.webkitExitFullscreen();
    if (document.msExitFullscreen) document.msExitFullscreen();
    if (document.mozCancelFullScreen) document.mozCancelFullScreen();
  } else {
    if (body.requestFullscreen) body.requestFullscreen();
    if (body.webkitRequestFullscreen) body.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
    if (body.msRequestFullscreen) body.msRequestFullscreen();
    if (body.mozRequestFullScreen) body.mozRequestFullScreen();
  }
};

// https://stackoverflow.com/a/2117523
const uuidv4 = () => {
  return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
    (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
  );
}

export {
  toggleFullScreen,
  uuidv4,
  weAreFullscreen,
};