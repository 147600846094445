import React from 'react';
import map from 'lodash/map';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import TableCell from './TableCell';

const Container = styled.div`
  border-bottom: 1px solid rgba(255, 255, 255, 0.4);
  overflow: auto;
  max-height: calc(100% - 85px);
`;

const StreamTable = ({ streams }) => (
  <Container>
    {map(streams, (stream) => {
      return (
        <TableCell key={stream.id}
          stream={stream}
          text={stream.display_name}
          image={stream.logo} />
      );
    })}
  </Container>
);

StreamTable.propTypes = {
  streams: PropTypes.array,
  onChange: PropTypes.func,
};

StreamTable.defaultProps = {
  streams: [],
  onChange: () => {},
};

export default StreamTable;
