import React, { useState, useContext, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { StreamsContext } from '../../contexts/StreamsContext';

const Form = styled.form`
  padding: 13px;
  padding-top: 0;
`;
Form.displayName = 'Form';

const Input = styled.input`
  font-size: 1rem;
  width: 100%;
  border: 1px solid #fff;
  margin: 0;
  padding: 8px;
  border-radius: 3px;
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
`;
Input.displayName = 'Input';

const AddStreamForm = ({ open }) => {
  const { addStream } = useContext(StreamsContext);
  const [streamNames, setStreamNames] = useState('');
  const inputRef = useRef(null);

  const submitForm = ev => {
    ev.preventDefault();
    addStream(streamNames);
    setStreamNames('');
  };

  useEffect(() => inputRef.current.focus(), [open]);

  return (
    <Form onSubmit={submitForm}>
      <Input
        ref={inputRef}
        value={streamNames}
        placeholder="Add Streams"
        onChange={ev => setStreamNames(ev.target.value)}
      />
    </Form>
  );
};

AddStreamForm.propTypes = {
  open: PropTypes.bool,
};

AddStreamForm.defaultProps = {
  open: false,
};

export default AddStreamForm;