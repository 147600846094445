import React, { useState, useContext, useEffect } from 'react';
import { StreamsContext } from '../contexts/StreamsContext';

import NavMenu from '../components/NavMenu';
import StreamBoard from '../components/stream-board';
import ErrorBoundary from '../components/error-boundary';
import Twitch from '../services/twitch.service';

const twitch = new Twitch(process.env.REACT_APP_TWITCH_ID);

const Home = () => {
  const [streamWidth, setStreamWidth] = useState(0);
  const [streamHeight, setStreamHeight] = useState(0);
  const { streams } = useContext(StreamsContext);

  useEffect(() => { // set up window resize listener, may need to use useLayoutEffect()
    const setStreamDimensions = () => {
      if (!streams.length) return;

      let width = document.body.offsetWidth;   // using this prevents scrollbar problems
      let height = Math.floor(width * 9 / 16); // assume 16:9 aspect ratio
      let colCount = 1;

      while (height * Math.ceil(streams.length / colCount) > window.innerHeight && height > 100) {
        width = Math.floor(document.body.offsetWidth / ++colCount);
        height = Math.floor(width * 9 / 16);
      }

      if (height > window.innerHeight) height = window.innerHeight;

      if (streamWidth !== width) setStreamWidth(width);
      if (streamHeight !== height) setStreamHeight(height);
    };

    window.addEventListener('resize', setStreamDimensions);
    setStreamDimensions();

    return () => window.removeEventListener('resize', setStreamDimensions);
  });

  return (
    <>
      <NavMenu />
      <ErrorBoundary>
        {streams.map(stream => (
          <StreamBoard
            key={stream.id}
            stream={stream}
            type={stream.type}
            width={streamWidth}
            height={streamHeight}
          />
        ))}
      </ErrorBoundary>
    </>
  );
};

export default Home;
