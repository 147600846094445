import assign from 'lodash/assign';
import isString from 'lodash/isString';

const apiUrl = 'https://api.twitch.tv/helix';
const apiV5Url = 'https://api.twitch.tv/kraken';

class Twitch {
  constructor(clientId) {
    assign(this, {
      clientId
    });
  }

  get(data) {
    let headers = assign({ 'Client-ID': this.clientId }, data.headers);
    let url = apiUrl + data.url;
    if (data.v5) {
      headers.Accept = 'application/vnd.twitchtv.v5+json';
      url = apiV5Url + data.url;
    }
    return fetch(url, { headers }).then(resp => resp.json());
  }

  idQueryString(ids) {
    if (!ids || !ids.length) return '';

    return ids.map((name) => {
      if (isString(name)) return `login=${name.toLowerCase()}`;
      return `id=${name}`;
    }).join('&');
  }

  getChannels(streamNames) {
    if (!streamNames || !streamNames.length) return Promise.resolve({ data: [] });
    return this.get({ url: `/users/?${this.idQueryString(streamNames)}` });
  }

  getChannel(streamName) {
    return this.getChannels([streamName]).then(result => result.data[0]);
  }

  getStreams(streamNames) {
    return this.get({ url: `/streams/?${this.idQueryString(streamNames)}` });
  }

  getStream(streamId) {
    return this.getStreams([streamId]);
  }

  getStreamsByUserId(usersIds) {
    // user_id
  }

  getGames(ids) {
    if (isString(ids)) {
      return this.searchGames(ids);
    } else {
      return this.getGamesByIds(ids);
    }
  }

  artUrlsFromTemplate(template) {
    const large = { x: 272, y: 340 };
    const medium = { x: 136, y: 190 };
    const small = { x: 52, y: 72 };

    return {
      large: template.replace('{width}', large.x).replace('{height}', large.y),
      medium: template.replace('{width}', medium.x).replace('{height}', medium.y),
      small: template.replace('{width}', small.x).replace('{height}', small.y)
    };
  }

  searchGames(searchString) {
    return this.get({
      url: `/search/games?query=${encodeURIComponent(searchString)}`,
      v5: true
    }).then(resp => (resp.games || []).map((game) => {
      return {
        id: game._id,
        name: game.name,
        art: this.artUrlsFromTemplate(game.box.template),
      }
    }));
  }

  getGamesByIds(ids) {
    let idsQuery = ids.map(id => `id=${encodeURIComponent(id)}`).join('&');
    return this.get({ url: `/games?${idsQuery}` })
      .then(resp => resp.data.map((game) => {
        return {
          id: game.id,
          name: game.name,
          art: this.artUrlsFromTemplate(game.box_art_url)
        };
      }));
  }

  // getGames - /games?id= <- really? check docs
  // searchGames - /search/games?query= - uses v5 url
}

export default Twitch;