import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Flex, Box } from 'reflexbox';
import {
  FaTimesCircle as CloseButton,
  FaRedoAlt as ReloadStreamButton
} from 'react-icons/fa';
import { StreamsContext } from '../../contexts/StreamsContext';

import Control from './Control';

const Container = styled(Flex).attrs({
  flexDirection: 'row',
  justifyContent: 'flex-end',
  alignItems: 'center',
  px: 2,
})`
  color: rgba(255, 255, 255, 0.6);
  position: absolute;
  right: 0;
  transition: 150ms;
  transform: translateX(${props => props.show? 0 : '100%'});
  opacity: ${props => props.show? 1 : 0};
`;
Container.displayName = 'ControlsContainer';

const ControlStrip = ({ show, stream }) => {
  const { removeStream, reloadStream } = useContext(StreamsContext);

  return (
    <Container show={show}>
      <Control>
        <ReloadStreamButton onClick={() => reloadStream(stream.name)} />
      </Control>

      <Control onClick={() => removeStream(stream.name)}>
        <CloseButton />
      </Control>
    </Container>
  )
};

ControlStrip.propTypes = {
  show: PropTypes.bool.isRequired,
  stream: PropTypes.object.isRequired,
};

export default ControlStrip;