import React, { useState } from 'react';
import styled from 'styled-components';
import { Box } from 'reflexbox';

const Container = styled(Box).attrs({
  mx: 1,
})`
  cursor: pointer;
  transition: 50ms;
  transform: scale(${props => props.scale || 1.0});
`;
Container.displayName = 'ControlContainer';

const Control = props => {
  const [scale, setScale] = useState(1.0);
  
  const shrink = () => setScale(0.8);
  const grow   = () => setScale(1.0);

  return (
    <Container
      onMouseDown={shrink}
      onMouseUp={grow}
      onMouseLeave={grow}
      scale={scale}
      {...props}
    >
      {props.children}
    </Container>
  );
}

export default Control;