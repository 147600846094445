import PropTypes from 'prop-types';
import styled from 'styled-components';

const Overlay = styled.div`
  display: block;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  z-index: ${props => props.zIndex};
`;
Overlay.displayName = 'Overlay';

Overlay.propTypes = {
  zIndex: PropTypes.number,
};

Overlay.defaultProps = {
  zIndex: 9,
};

export default Overlay;