import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components'

const Version = styled.div`
  content: 'Streams v${props => props.version || '∞'}';
  position: absolute;
  display: inline;
  bottom: 13px;
  right: 13px;
  text-align: right;
  font-size: 0.6875rem;
`;

const VersionBadge = ({ version }) => (
  <Version>Streams v{version}</Version>
);

VersionBadge.propTypes = {
  version: PropTypes.number.isRequired
};

export default VersionBadge;