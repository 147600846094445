import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  FaExpandAlt as ExpandIcon,
  FaCompressAlt as CompressIcon
} from 'react-icons/fa';

import { weAreFullscreen, toggleFullScreen } from '../../utils';

const Container = styled.div`
  position: absolute;
  cursor: pointer;
  top: 12px;
  right: 12px;
  opacity: ${props => props.show? 1 : 0};
  transition: 150ms;
  transition-delay: 200ms;
`;
Container.displayName = 'ToggleFullscreenContainer';

const ToggleFullscreen = ({ show }) => {
  const fullscreen = weAreFullscreen();

  return (
    <Container onClick={toggleFullScreen} show={show}>
      {fullscreen && <CompressIcon />}
      {!fullscreen && <ExpandIcon />}
    </Container>
  );
};

ToggleFullscreen.propTypes = {
  show: PropTypes.bool.isRequired,
};

export default ToggleFullscreen;