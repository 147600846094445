import React from 'react';
import { uniq } from 'lodash';
import streamTypes from '../components/streamTypes';
import { uuidv4 } from '../utils';

import TwitchLogo from '../assets/twitch.svg';
import YoutubeLogo from '../assets/youtube.svg'

const STREAMS_KEY = 'streams';

const getStreams = () => JSON.parse(localStorage.getItem(STREAMS_KEY) || '[]');
const saveStreams = (streams) => localStorage.setItem(STREAMS_KEY, JSON.stringify(streams || '[]'));

export const streamsService = {
  streams: [],
  getStreams,
  saveStreams,
  removeStream: streamName => {
    const streams = getStreams()
      .filter(s => s.name !== streamName);
    saveStreams(streams);
  },
  addStream: streamName => {
    const currentStreams = getStreams().map(s => s.name);
    const streamNames = uniq(
      streamName.split(/[, ]/)
        .map(stream => stream.trim())
        .filter(name => name !== '' && currentStreams.indexOf(name) === -1)
    );

    const twitchStreams = [];
    const youtubeStreams = [];

    streamNames.forEach(name => {
      if (/^yt:/.test(name)) {                            // yt:12345
        youtubeStreams.push(name.split(':')[1]);
      } else if (/^https?:\/\/youtu.be/.test(name)) {     // https://youtu.be/12345
        youtubeStreams.push(name.match(/([^/]+$)/g)[0]);
      } else if (/watch\?v=(.+)$/.test(name)) {           // https://youtube.com/watch?v=12345
        youtubeStreams.push(name.match(/([^=]+)$/g)[0]);
      } else {
        twitchStreams.push(name);
      }
    });

    const youtubeChannels = youtubeStreams.map(stream => ({
      name: stream,
      display_name: 'Youtube',
      type: streamTypes.YOUTUBE.type,
      logo: YoutubeLogo,
      id: uuidv4()
    }));

    const twitchChannels = twitchStreams.map(stream => ({
      name: stream,
      id: uuidv4(),
      display_name: stream,
      logo: TwitchLogo,
      video_banner: '',
      muted: true,
      type: streamTypes.TWITCH.type,
    }));

    saveStreams([
      ...getStreams(),
      ...twitchChannels,
      ...youtubeChannels
    ]);
  },
  clearStreams: () => saveStreams([]),
};

export const StreamsContext = React.createContext(getStreams());
