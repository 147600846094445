import React, { useState } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { StreamsContext, streamsService } from './contexts/StreamsContext';
import { createGlobalStyle } from 'styled-components';
import { uuidv4 } from './utils';

import Home from './pages/home.js';

const GlobalStyles = createGlobalStyle`
  * {
    box-sizing: border-box;
  }

  html, body {
    margin: 0;
    padding: 0;
    font-size: 16px;
    height: 100%;
  }

  body {
    font-size: 0;
    background: #fff linear-gradient(rgba(0, 0, 0, 1.0) 50%, rgba(0, 0, 0, 0.8));
    font-family: "HelveticaNeue", "Helvetica", "Arial", sans-serif;

    &::-webkit-scrollbar {
      display: none;
    }
  }
`;

const App = () => {
  const [streamList, setStreamList] = useState(streamsService.getStreams());
  const streamsContext = {
    streams: streamList,
    addStream: stream => {
      streamsService.addStream(stream);
      setStreamList(streamsService.getStreams());
    },
    removeStream: stream => {
      streamsService.removeStream(stream);
      setStreamList(streamsService.getStreams());
    },
    clearStreams: () => {
      streamsService.clearStreams();
      setStreamList([]);
    },
    reloadStream: (streamName) => {
      const streamIndex = streamList.findIndex(s => s.name === streamName);
      if (streamIndex === -1) return;

      streamList[streamIndex].id = uuidv4();
      setStreamList([...streamList]);
    },
  };

  return (
    <StreamsContext.Provider value={streamsContext}>
      <GlobalStyles />
      <Router>
        <Route path="/" component={Home} />
      </Router>
    </StreamsContext.Provider>
  )
};

export default App;
